import React from "react"
import { graphql } from "gatsby"
import Title from "../../components/title"
import Layout from "../../components/layout"
import StyledHero from "../../components/styledHero"
import styles from "../../css/offer.module.css"
import SEO from "../../components/seo"

const digitalsenior = ({ data, location }) => {
  return (
    <Layout location={location.pathname}>
      <SEO title="Leistungsangebot digitalsenior" />
      <StyledHero img={data.digitalseniorBcg.childImageSharp.fluid} />
      <section className={styles.offer}>
        <Title title="Leistungsangebot" subtitle="digitalsenior" />
        <div className={styles.offerCenter}>
          <header>
            <a
              href="https://www.digitalsenior.de/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.partnerLogo}
                src="/digitalsenior.png"
                alt="digitalsenior Logo"
              />
            </a>
            <h4>Pro Bono Leistungsangebot von Hubertus Schleuter – digitalsenior.de </h4>
          </header>
          <article className={styles.offerText}>
            <p>
              Wir unterstützen bei der Aufnahme und Bewertung der existierenden Digitalstrategie und die Unterstützung zur Entwicklung der benötigten Digitalstrategie zur Absicherung der Wertschöpfung Ihres Unternehmens in der Transformation
            </p>
            <p>
              Wir helfen bei der Analyse der Herausforderungen und des Änderungsbedarfs und leiten mit Ihnen die die Entwicklung der nötigen Aktivitäten ein. Die Anforderungen an Veränderung in Organisation, Technologie, Kommunikation und Kollaboration werden methodisch erfasst und ihre Implementierung vorbereitet
            </p>
            <p>
              Wir unterstützen bei der Implementierung und der laufenden Optimierung Ihrer Digitalen Transformation. Unser methodischer Ansatz hilft in der Umsetzungsphase die wesentlichen, eingeleiteten Veränderungen zu erkennen und zu bewerten. 
            </p>
          </article>
          <footer>
            <a
              href="mailto:kontakt@vscovidunited.de"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-primary"
            >
              Angebot anfordern
            </a>
          </footer>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    digitalseniorBcg: file(relativePath: { eq: "showroomBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default digitalsenior
